import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px 1px rgba(160, 161, 188, 0.14) !important;
  transition: all 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  height: 100%;
  & br {
    @media (min-width: 576px) and (max-width: 991px) {
      display: none;
    }
    @media (min-width: 1200px) {
      display: none;
    }
  }
  .toolbox-detail-upcoming--text-container {
    p {
      margin: 0;
    }
  }
  &:hover {
    box-shadow: 0 8px 24px 8px rgba(160, 161, 188, 0.14) !important;
    top: -4px;
    cursor: pointer;
  }
  .toolbox-detail-upcoming--inner-container {
    padding: 24px;
    height: 100%;
    display: flex;
    position: relative;
  }
`;

const Button = styled.div`
  font-size: var(--font-size-16);
  font-weight: bold;
  line-height: var(--line-height-150);
  color: var(--titan-blue-3);
  margin-top: 15px;
  position: absolute;
  bottom: 20px;

  & > svg {
    vertical-align: middle;
    margin-left: 6px;
    margin-top: -1px;
  }
`;

export { Button, Container };
