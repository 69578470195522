import styled from 'styled-components';
import { Row } from 'components/Core/Grid';

const RelatedPosts = styled.div`
  background: var(--skyline-1);
  @media (max-width: 991px) {
    margin-top: 60px;
  }
`;

const InnerContainer = styled.div`
  max-width: 1296px;
  margin: 0px auto;
  padding: 65px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 479px) {
    padding: 65px 20px;
  }
`;

const PostsContainer = styled(Row)`
  width: 100%;
  justify-content: center;
`;

export { InnerContainer, RelatedPosts, PostsContainer };
