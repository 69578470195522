import React from 'react';
import Slider from 'react-slick';
import ToolboxSplashUpcomingCard from './Card';
import rightArrow from 'images/right-arrow-thin-gray.svg';
import leftArrow from 'images/left-arrow-thin-gray.svg';
import { ToolboxSplashUpcomingContainer } from './styles';

const ToolboxSplashUpcoming = ({ upcomingPosts, sidebar = false }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    autoplay: false,
    autoplaySpeed: 5000,
    nextArrow: <img src={rightArrow} />,
    prevArrow: <img src={leftArrow} />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: upcomingPosts.length < 3 ? 3 : 3.1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: upcomingPosts.length === 1 ? 1 : 1.1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const content = upcomingPosts.map((post, index) => {
    return (
      <div className="toolbox-detail-upcoming--slide-col" key={index}>
        <ToolboxSplashUpcomingCard post={post} sidebar={sidebar} />
      </div>
    );
  });

  return (
    <ToolboxSplashUpcomingContainer className={sidebar ? 'sidebar-mode' : ''}>
      <h6 className="toolbox-detail-upcoming--headline">Upcoming Webinars</h6>
      <div className="toolbox-detail-upcoming--content ">
        {sidebar ? content : <Slider {...settings}>{content}</Slider>}
      </div>
    </ToolboxSplashUpcomingContainer>
  );
};

export default ToolboxSplashUpcoming;
