import React from 'react';
import { Col } from 'components/Core/Grid';
import { format } from 'utils/date';
import { getSubheading } from 'utils/text';
import Cards from 'components/Core/Cards';
import Text from 'components/Core/Text';
import { splashCheck } from 'utils/urlHelper';
import Bookmark from 'components/Page/Toolbox/Bookmark';
import { InnerContainer, PostsContainer, RelatedPosts } from './styles';

const WebinarOnDemandRelatedPosts = ({ relatedWebinars, placeholderImage }) => {
  return (
    <RelatedPosts>
      <InnerContainer>
        <Text type="h4" marginMobile="0 0 40px 0" style={{ textAlign: 'center' }}>
          Related On-Demand Webinars
        </Text>
        <PostsContainer
          gutter={[
            { xs: 0, sm: 32, md: 32, lg: 32 },
            { xs: 40, sm: 40, md: 40, lg: 0 },
          ]}
          type="flex"
        >
          {relatedWebinars?.slice(0, 3)?.map((webinar, index) => {
            const ctaLink = splashCheck(webinar?.onDemandRedirectSlug || webinar.seo.pageUrl);

            return (
              <Col xs={24} sm={8} key={`webinar-on-demand-related-${index}`}>
                <Bookmark type="webinar" url={ctaLink} data={webinar} title={webinar.webinarTitle}>
                  <Cards
                    type={'Image'}
                    bodyType={'simple'}
                    coverImage={webinar.featuredImage || placeholderImage}
                    category={format(webinar.webinarUTCDate, 'MMMM Do, YYYY')}
                    title={webinar.webinarTitle}
                    description={getSubheading(webinar.excerpt?.excerpt, webinar.mainContent, 120)}
                    ctaTitle={'View webinar'}
                    ctaLink={webinar.seo?.pageUrl}
                  />
                </Bookmark>
              </Col>
            );
          })}
        </PostsContainer>
      </InnerContainer>
    </RelatedPosts>
  );
};

export default WebinarOnDemandRelatedPosts;
