import styled from 'styled-components';

const ToolboxSplashUpcomingContainer = styled.div`
  padding: 0 28px;
  margin-top: 40px;

  .toolbox-detail-upcoming--inner-container {
    min-height: 200px;
    @media (max-width: 1199px) {
      min-height: 250px;
    }
    @media (max-width: 767px) {
      min-height: 200px;
    }
    @media (max-width: 575px) {
      min-height: 250px;
    }
  }
  .toolbox-detail-upcoming--headline {
    margin-bottom: 20px;
  }
  .toolbox-detail-upcoming--icon {
    width: 40px;
    height: 40px;
    margin: -8px 15px 0 -8px;
  }
  .toolbox-detail-upcoming--title-container {
    color: var(--dark);
  }
  .toolbox-detail-upcoming--title {
    margin: 5px 0 40px 0;
    color: var(--darkest);
  }
  .slick-slider {
    margin: 0 -20px;
  }
  .slick-track {
    margin: 0;
    overflow: visible;
  }
  .slick-list {
    padding: 20px 0 30px 0;
    overflow-x: visible;
  }
  .slick-disabled {
    display: none !important;
  }
  .toolbox-detail-upcoming--slide-col {
    height: 100%;
    padding: 0 20px;
    width: 33.333%;
  }
  @media (max-width: 991px) {
    padding: 0;
    margin-bottom: 60px;
  }

  &.sidebar-mode {
    padding: 0;

    .toolbox-detail-upcoming--inner-container {
      min-height: 0 !important;
    }

    .toolbox-detail-upcoming--title {
      margin-bottom: 60px;
      font-weight: var(--font-weight-600);
    }

    .toolbox-detail-upcoming--content {
      display: flex;
      flex-direction: column;

      .toolbox-detail-upcoming--slide-col {
        width: 100%;
        padding: 0;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export { ToolboxSplashUpcomingContainer };
