import styled from 'styled-components';

const Container = styled.div`
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  p {
    font-size: var(--font-size-16);
    color: var(--darkest);
  }
`;

const InnerContainer = styled.div`
  height: 100%;
  width: 65%;
  padding: 0 40px 50px 0;
  position: relative;
  margin: 0;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 0 40px;
  }
`;

const SidebarContainer = styled.div`
  width: 35%;
  padding: 0;
  div:first-child {
    margin-top: 0px !important;
  }
  @media (max-width: 767px) {
    padding: 0;
    width: 100%;
  }
`;

export { Container, InnerContainer, SidebarContainer };
