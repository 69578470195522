import React from 'react';
import Button from 'components/Core/Button';

const WebinarMainContentCTA = ({ content, search, ...otherProps }) => {
  return (
    <Button
      dataPosition="Webinar Register Main Content - CTA"
      style={{ display: 'flex', justifyContent: 'center', margin: '40px 20%' }}
      to={`${content.url}${content.carryUtmParameters && search ? search : ''}`}
    >
      {content.buttonLabel}
    </Button>
  );
};

export default WebinarMainContentCTA;
