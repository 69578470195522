import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import Cookies from 'js-cookie';
import ToolboxLayout from 'layouts/toolboxLayout';
import DemoFormSection from 'components/Sections/DemoFormSection';
import WebinarLayout from 'components/Page/WebinarTemplates/Layout';
import WebinarHero from 'components/Page/WebinarTemplates/Hero';
import WebinarOnDemandMainContent from 'components/Page/WebinarTemplates/WebinarOnDemandTemplate/MainContent';
import WebinarOnDemandRelatedPosts from 'components/Page/WebinarTemplates/WebinarOnDemandTemplate/RelatedPosts';
import WebinarSpeakerCarousel from 'components/Page/WebinarTemplates/SpeakerCarousel';
import {
  getUpcomingPosts,
  filterPostsFromSplashAndToolbox,
} from 'components/Page/Webinar/utils/postsUtils';
import { queryStringToCookies } from 'utils/urlHelper';
import SweetScrollContext from 'utils/SweetScrollContext';
import headsetIcon from 'images/headset-gray.svg';
import BookmarkContext from 'utils/BookmarkContext';
import { getCategories } from 'components/Page/Toolbox/utils';
import UserContext from 'utils/UserContext';

const WebinarOnDemandTemplate = (props) => {
  const {
    data: { contentfulWebinar, contentfulImage, allContentfulWebinar, onDemandForm },
  } = props;
  const {
    callToAction,
    eventId,
    eventKey,
    featuredImage,
    inlineMarketingForm,
    keyTakeaways,
    mainContent,
    seo: seoData,
    speakers,
    subtitle,
    webinarMonthDayYear,
    webinarTitle,
    wistiaVideo,
    wistiaVideoId,
  } = contentfulWebinar;
  const [webinarRedirectUrl, setWebinarRedirectUrl] = useState(null);
  const redirectToOn24 = eventKey && eventId;
  const allWebinars = filterPostsFromSplashAndToolbox(
    allContentfulWebinar.edges.map(({ node }) => node),
  );
  const splashPagePosts = filterPostsFromSplashAndToolbox(allWebinars);
  const upcomingPosts = getUpcomingPosts(splashPagePosts);
  const { scrollToAnchor } = useContext(SweetScrollContext);

  seoData.pageCategory = 'Webinar OnDemand';

  const getRelatedWebinars = () => {
    const webinarsExcludingCurrent = allWebinars.filter(
      (webinar) => webinar.id !== contentfulWebinar.id,
    );

    const rankedWebinars = webinarsExcludingCurrent.map((webinar) => {
      let matches = 0;

      if (webinar?.seo?.taxonomy?.industries && contentfulWebinar?.seo?.taxonomy?.industries) {
        if (webinar?.seo?.taxonomy.industries.includes('All Industries')) {
          matches += contentfulWebinar?.seo?.taxonomy.industries.length;
        } else {
          matches += webinar?.seo?.taxonomy.industries.filter((industry) =>
            contentfulWebinar?.seo?.taxonomy.industries.includes(industry),
          ).length;
        }
      }

      if (webinar?.seo?.taxonomy?.topics && contentfulWebinar?.seo?.taxonomy?.topics) {
        if (webinar?.seo?.taxonomy?.topics?.includes('All Topics')) {
          matches += contentfulWebinar?.seo?.taxonomy.topics.length;
        } else {
          matches += webinar?.seo?.taxonomy.topics.filter((topic) =>
            contentfulWebinar?.seo?.taxonomy.topics.includes(topic),
          ).length;
        }
      }

      return { matches, ...webinar };
    });

    return rankedWebinars.sort((b, a) => a.matches - b.matches);
  };

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selSpeaker, setSelSpeaker] = useState(0);
  const relatedWebinars = getRelatedWebinars();
  const placeholderImage = contentfulImage?.image?.file;

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Webinars',
        icon: headsetIcon,
        url: '/webinar',
      },
      {
        title: 'On-Demand Video',
        icon: null,
        url: null,
      },
    ],
    absolute: true,
    theme: 'dark',
  };

  const [search, setSearch] = useState(undefined);

  useEffect(() => {
    queryStringToCookies();
    setSearch(window?.location?.search);
    setFormSubmitted(Cookies.get('toolbox_form_submission') === 'true');
  }, []);

  const on24Redirect = () => {
    const cookieValues = {
      firstName: Cookies.get('firstName'),
      lastName: Cookies.get('lastName'),
      company: Cookies.get('company'),
      email: Cookies.get('email'),
      industry: Cookies.get('industry') || Cookies.get('otherIndustry'),
    };

    const { firstName, lastName, company, email, industry } = cookieValues;

    setWebinarRedirectUrl(
      `https://event.on24.com/interface/registration/autoreg/index.html?eventid=${eventId}&sessionid=1&key=${eventKey}&firstname=${firstName}&lastname=${lastName}&email=${email}&company=${company}&company_industry=${industry}`,
    );
  };

  useEffect(() => {
    if (redirectToOn24) {
      on24Redirect();
    }
  }, []);

  const onFormSuccess = () => {
    if (redirectToOn24) on24Redirect();

    Cookies.set('toolbox_form_submission', 'true');
    setFormSubmitted('true');
  };

  const handleClickSpeaker = (index) => {
    if (document) {
      scrollToAnchor('#webinar-speakers--carousel');
    }

    setSelSpeaker(index);
  };

  return (
    <BookmarkContext.Provider
      value={{
        subTitle: getCategories(contentfulWebinar),
        image: featuredImage?.image,
        type: 'webinar',
        title: webinarTitle,
        url: props.path,
      }}
    >
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={seoData} layout="toolbox" {...props}>
        <UserContext.Consumer>
          {({ userInfo }) => {
            const hideForm = formSubmitted || userInfo;
            return (
              <>
                <WebinarLayout showAddSocial={hideForm}>
                  <WebinarHero
                    date={webinarMonthDayYear}
                    form={inlineMarketingForm}
                    formSubmitted={hideForm}
                    onFormSuccess={onFormSuccess}
                    redirectToOn24={redirectToOn24}
                    shouldRedirect={redirectToOn24 && false}
                    title={webinarTitle}
                    type="on-demand"
                    webinarRedirectUrl={webinarRedirectUrl}
                  />
                  <WebinarOnDemandMainContent
                    formSubmitted={hideForm}
                    inlineMarketingForm={onDemandForm}
                    wistiaVideo={wistiaVideo}
                    wistiaVideoId={wistiaVideoId}
                    featuredImage={featuredImage}
                    title={webinarTitle}
                    subtitle={subtitle}
                    mainContent={mainContent}
                    callToAction={callToAction}
                    search={search}
                    keyTakeaways={keyTakeaways}
                    speakers={speakers}
                    upcomingPosts={upcomingPosts}
                    onClickSpeaker={handleClickSpeaker}
                  />
                </WebinarLayout>

                {speakers && <WebinarSpeakerCarousel speakers={speakers} selSpeaker={selSpeaker} />}

                {hideForm && (
                  <>
                    <WebinarOnDemandRelatedPosts
                      relatedWebinars={relatedWebinars}
                      placeholderImage={placeholderImage}
                    />

                    <DemoFormSection
                      backgroundColor="var(--titan-blue-3)"
                      formTitle="Let's get started"
                      description="Explore why 100,000+ contractors trust Service Titan to run their business."
                      title="Book a personalized demo today."
                    />
                  </>
                )}
              </>
            );
          }}
        </UserContext.Consumer>
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export const WebinarOnDemandTemplatePageQuery = graphql`
  query WebinarOnDemandBySlug($slug: String!) {
    contentfulWebinar(seo: { pageUrl: { eq: $slug } }) {
      ...Webinar
    }
    allContentfulWebinar {
      edges {
        node {
          ...Webinar
        }
      }
    }
    contentfulImage(contentful_id: { eq: "8TSIiE3INirzb6LpyqmvF" }) {
      ...WebsiteImage
    }
    onDemandForm: contentfulInlineMarketingForm(internalName: { eq: "Webinar On-Demand" }) {
      ...InlineMarketingForm
    }
  }
`;

export default WebinarOnDemandTemplate;
