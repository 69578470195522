import React from 'react';
import WebsiteImage from 'components/WebsiteImage';
import Text from 'components/Core/Text';
import WistiaVideo from 'components/Core/WistiaVideo';
import StyledRichText from 'components/RichText/StyledRichText';
import WebinarKeyTakeaways from 'components/Page/WebinarTemplates/KeyTakeaways';
import WebinarMainContentCTA from './WebinarMainContentCTA';
import ToolboxSplashUpcoming from 'components/Page/Webinar/Upcoming';
import { saveForm, init } from 'services/marketoService';
import { getStoredMarketoData } from 'utils/marketo';
import WebinarGuestSpeaker from '../../GuestSpeaker';
import { Container, InnerContainer, SidebarContainer } from './styles';
import { WithUserContext } from 'utils/UserContext';
import { getMarketoFormId } from 'utils/marketo';
import useMarketoFormData from 'hooks/useMarketoFormData';
import { formSubmittedEvent } from 'services/dataLayerService';

const WebinarOnDemandMainContent = ({
  formSubmitted,
  inlineMarketingForm,
  wistiaVideo,
  wistiaVideoId,
  featuredImage,
  title,
  subtitle,
  mainContent,
  callToAction,
  search,
  keyTakeaways,
  speakers,
  upcomingPosts,
  onClickSpeaker,
  userInfo,
}) => {
  const formData = useMarketoFormData();

  const marketoSubmit = () => {
    if (!inlineMarketingForm) {
      return;
    }

    const marketoForm = formData.find(
      (x) => x.id === getMarketoFormId(inlineMarketingForm?.marketoForm),
    );

    init(marketoForm.id);

    const data = getStoredMarketoData(marketoForm, userInfo);

    formSubmittedEvent({ form_type: 'CQL Form', category: 'form', page_title: title, ...data });

    saveForm({
      marketoFormId: marketoForm.id,
      ...data,
    });
  };

  const getFeaturedContent = () => {
    if (wistiaVideo) {
      return <WistiaVideo size="Large" onPlay={marketoSubmit} {...wistiaVideo} inline />;
    }

    if (wistiaVideoId) {
      return (
        <WistiaVideo
          onPlay={marketoSubmit}
          size="Large"
          url={`https://servicetitan-1.wistia.com/medias/${wistiaVideoId}`}
          inline
        />
      );
    }

    if (featuredImage) {
      return (
        <WebsiteImage
          image={featuredImage?.image}
          alt={featuredImage?.image?.alt}
          style={{ width: '100%', height: 'auto' }}
        />
      );
    }

    return null;
  };

  return (
    <Container>
      <InnerContainer>
        {formSubmitted && getFeaturedContent()}
        {speakers && !formSubmitted && (
          <WebinarGuestSpeaker speakers={speakers} onClickSpeaker={onClickSpeaker} />
        )}
        {subtitle && (
          <Text type="h6" style={{ marginTop: '10px', marginBottom: '-20px' }}>
            {subtitle}
          </Text>
        )}
        {callToAction && <WebinarMainContentCTA content={callToAction} search={search} />}
        {keyTakeaways && (
          <WebinarKeyTakeaways keyTakeaways={keyTakeaways} bottomSection={!speakers} />
        )}
        {mainContent && (
          <StyledRichText className="webinar-on-demand--main-content" content={mainContent} />
        )}
      </InnerContainer>

      {formSubmitted && (
        <SidebarContainer>
          {speakers?.length > 0 && (
            <WebinarGuestSpeaker
              speakers={speakers}
              onClickSpeaker={onClickSpeaker}
              showAll={upcomingPosts.length === 0}
              sidebar
            />
          )}
          {upcomingPosts.length > 0 && (
            <ToolboxSplashUpcoming upcomingPosts={upcomingPosts} sidebar />
          )}
        </SidebarContainer>
      )}
    </Container>
  );
};

export default WithUserContext(WebinarOnDemandMainContent);
